import { formatISO } from 'date-fns/formatISO'

import { formatPrice } from '@sholdi/shared/helpers/formatPrice'
import { getCategoryUrl } from '@sholdi/shared/helpers/categories'
import getEnv from './env/getEnv'

const APP_URL = getEnv('NEXT_PUBLIC_APP_URL', 'https://sholdi.store')
const CDN_URL = getEnv('NEXT_PUBLIC_SHOLDI_IMAGE_CDN_URL', 'https://cdn.sholdi.store')

export const getShopOrganizationJson = shop => ({
  '@context': 'https://schema.org',
  '@type': 'Store',
  name: shop.name,
  url: `${APP_URL}`,
  // TODO: full url
  ...(shop.logo ? { logo: `${CDN_URL}/${shop.logo}` } : {}),
  ...(shop.phones ? { telephone: shop.phones[0] } : {}),
  ...(shop.description ? { description: shop.description } : {}),
  review: {
    '@type': 'Review',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: shop?.rating,
    },
  },
})

export const getBreadcrumbsJson = additionalBreadcrumbs => ({
  '@context': 'https://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      item: {
        '@id': APP_URL,
        name: 'Home',
      },
    },
    ...additionalBreadcrumbs,
  ],
})

const getVariationValueName = (productVariationOptions = [], combination) =>
  productVariationOptions.find(productVariationOption =>
    combination.combinationString
      .toLowerCase()
      .includes(
        productVariationOption.variationOption.variationOptionDescriptions[0].name.toLowerCase(),
      ),
  )

export const getProductJson = (product, symbol, offers) => {
  const {
    mainImage,
    categoryName,
    productCombination,
    manufacturer,
    shopName,
    shopLogo,
    name,
    shortDescription,
    maxRating,
    avgRating,
    count,
    reviews,
    // productDescriptions: [{ name, shortDescription }],
  } = product

  let variants = []

  const colorProductVariant = product.productVariations.find(
    pV => pV.variation.variationDescriptions?.[0]?.name === 'Boja',
  )

  if (product.productVariations.filter(pV => pV.variation.allowCombinations).length) {
    variants = {
      hasVariant: product.productCombinations.map(combination => {
        const color = getVariationValueName(
          colorProductVariant?.productVariationOptions,
          combination,
        )
        return {
          '@type': 'Product',
          name: product.name,
          ...(combination.sku ? { sku: combination.sku } : {}),
          ...(colorProductVariant && color
            ? {
                color: color.variationOption.variationOptionDescriptions[0].name,
              }
            : {}),
          additionalProperty: product.productVariations
            .filter(pV => pV.variation.allowCombinations)
            .filter(pV => pV.variationId !== colorProductVariant?.variationId)
            .map(pV => {
              const variationName = pV.variation.variationDescriptions[0].name
              // gets the unit from the variation name - from brackets
              const unit = variationName.match(/\((.*?)\)/)
              const value = pV.productVariationOptions.find(productVariationOption =>
                combination.relatedVariationOptions.find(
                  relatedVariationOption =>
                    productVariationOption.variationOptionId ===
                    relatedVariationOption.variationOptionId,
                ),
              )

              return {
                '@type': 'PropertyValue',
                name: variationName,
                // unitCode: "E34",

                ...(unit && unit.length > 1 ? { unitText: unit[1] } : {}),
                value: value?.variationOption.variationOptionDescriptions[0].name,
              }
            }),
        }
      }),
    }
  }

  const productHasMultipleVariants = Object.keys(variants).length

  return {
    '@context': 'https://www.schema.org',
    '@type': productHasMultipleVariants ? 'ProductGroup' : 'Product',
    ...(manufacturer
      ? {
          brand: {
            type: '@brand',
            name: manufacturer?.name,
          },
        }
      : {}),
    ...(mainImage ? { logo: mainImage } : {}),
    name,
    category: categoryName,
    itemCondition: 'NEW',
    priceCurrency: symbol,
    image: mainImage,
    ...(productHasMultipleVariants ? variants : {}),
    ...(shortDescription ? { description: shortDescription } : {}),
    // TODO: fill out
    aggregateRating: {
      '@type': 'aggregateRating',
      ratingValue: avgRating,
      reviewCount: count,
      bestRating: maxRating,
    },
    review: reviews.map(review => ({
      '@type': 'Review',
      datePublished: formatISO(review.createdAt, { representation: 'date' }),
      reviewBody: review.message,
      reviewRating: {
        '@type': 'Rating',
        ratingValue: review.rating,
      },
      author: {
        '@type': 'Person',
        name: review.user.username,
      },
    })),
    sku: productCombination?.sku,
    offers: {
      '@type': 'AggregateOffer',
      priceCurrency: symbol,
      price: productCombination?.price / 100 || 0,
      highPrice: formatPrice(offers.maxPrice),
      lowPrice: formatPrice(offers.minPrice),
      offerCount: offers.length,
      offers: offers,
      // priceValidUntil: '2020-11-05',
      itemCondition: 'https://schema.org/UsedCondition',
      availability: 'https://schema.org/InStock',
      seller: {
        '@type': 'Organization',
        name: shopName,
        ...(shopLogo ? { logo: shopLogo } : {}),
      },
    },
  }
}

export const getProductPageBreadcrumbsJson = (product, categories = []) =>
  getBreadcrumbsJson([
    categories.map(category => ({
      '@type': 'ListItem',
      position: 4,
      item: {
        '@id': `${APP_URL}${getCategoryUrl(category)}`,
        name: category.name,
        ...(category.image ? { image: category.image } : {}),
      },
    })),
    {
      '@type': 'ListItem',
      position: 4,
      item: {
        '@id': `${APP_URL}/products/${product.id}/${product.slug}`,
        name: product.name,
        ...(product.mainImage ? { image: product.mainImage } : {}),
      },
    },
  ])
