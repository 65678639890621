import { fonts } from './fonts/utils'
const loadFontFormPath = async fontPath => {
  const font = await import(`./fonts/${fontPath}`)
  return font.default
}

export const loadConfiguredFonts = async themeConfig => {
  let headingFontName = themeConfig['--font-heading'] || 'Inter'
  let bodyFontName = themeConfig['--font-body'] || 'Inter'
  let headingFontNamePath = fonts.some(font => font.body === headingFontName)
    ? `${headingFontName.replace(/ /g, '')}Heading`
    : headingFontName.replace(/ /g, '')
  let bodyFontNamePath = fonts.some(font => font.heading === bodyFontName)
    ? `${bodyFontName.replace(/ /g, '')}Body`
    : bodyFontName.replace(/ /g, '')

  const headingFont = await loadFontFormPath(headingFontNamePath)
  const bodyFont = await loadFontFormPath(bodyFontNamePath)

  return { headingFont, bodyFont }
}
