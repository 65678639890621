// Since you did not mention which libraries you used, i use `axios` and `js-cookie`. they both are very popular and have easy api.
// import axios from 'axios'
// import Cookies from 'js-cookie'
// import cookieP from 'cookie'
// import atob from 'atob'

import CREATE_LOGIN_LOG_MUTATION from '@sholdi/graphql/mutations/fingerprint/createLoginLog'

import initUrql from './urql/init-urql'

import getEnv from './env/getEnv'

const USER_NAME = 'x-hasura-username'
const DEFAULT_ROLE = 'x-hasura-default-role'
const ALLOWED_ROLES = 'x-hasura-allowed-roles'
const USER_ID = 'x-hasura-user-id'

// cookies are attached to req.header.cookie
// you can console.log(req.header.cookie) to see the cookies
// cookieKey is a  param, we pass jwt when we execute this function
export const getCookieFromReq = (req, cookieKey) => {
  let cookieP
  const {
    headers: { cookie },
  } = req

  if (!cookie) return undefined

  cookie.split(';').find(c => c.trim().startsWith(`${cookieKey}=`))

  if (!cookie) return undefined
  return cookieP.parse(cookie)
}

export const parseJwt = token => {
  if (typeof window === 'undefined') {
    return JSON.parse(Buffer.from(token?.split('.')[1], 'base64').toString())
  }
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )
  return JSON.parse(jsonPayload)
}

export const getSessionDetails = (req, cookieName = 'session') => {
  let Cookies

  const cookie = req ? getCookieFromReq(req, cookieName) : Cookies.getJSON(cookieName)
  if (cookie) {
    const { session } = cookie
    if (session && JSON.parse(session)) {
      return JSON.parse(session)
    }
  }

  return { isAuthenticated: false }
}

export const registerUserLog = async ({ req, res, data, ip }) => {
  // Log user signing
  const { client } = initUrql(
    'marketplaceAdmin',
    { req, res },
    getEnv('SHOLDI_HASURA_SECRET'),
  )

  await client
    .mutation(CREATE_LOGIN_LOG_MUTATION, {
      input: {
        ipAddress: String(ip),
        userId: data.user.id,
      },
    })
    .toPromise()

  return data
}

export const generateSessionDetails = data => {
  const { jwt_token: jwtToken, jwt_expires_in: jwtTokenExpiresIn } = data

  const jwtUserDetails = parseJwt(jwtToken)
  console.log('jwtUserDetails: ', jwtUserDetails)
  if (jwtUserDetails) {
    const userDetail = jwtUserDetails[getEnv('JWT_CLAIMS_NAMESPACE')]

    return {
      user: {
        username: userDetail[USER_NAME],
        allowedRoles: userDetail[ALLOWED_ROLES],
        defaultRole: userDetail[DEFAULT_ROLE],
        userId: userDetail[USER_ID],
      },
      token: jwtToken,
      expiresIn: jwtTokenExpiresIn,
      expires: new Date(Date.now() + jwtTokenExpiresIn * 1000),
      isAuthenticated: true,
    }
  }

  return {
    isAuthenticated: false,
  }
}
