/**
 * Gets an environment variable, throwing an error if it's not found
 * unless a fallback is provided
 * @param {string} key - The environment variable key to look up
 * @param {string} [fallback] - Optional fallback value if the key is not found
 * @throws {TypeError} If key is not a string
 * @throws {Error} If the environment variable is not found and no fallback provided
 * @returns {string} The environment variable value
 */
const getEnv = (key, fallback) => {
  if (typeof key !== 'string') {
    throw new TypeError('Environment variable key must be a string')
  }

  let value

  // Check Node.js environment first
  const hasProcessEnv = typeof process !== 'undefined' && process.env
  if (hasProcessEnv && process.env[key]) {
    value = process.env[key]
  }

  // If we're not in Node.js (i.e., in browser)
  if (!value && typeof window !== 'undefined' && window?.env?.[key]) {
    value = window.env[key]
  }

  // If no value was found and no fallback provided, throw error
  if (!value && fallback === undefined) {
    throw new Error(`Missing required environment variable: ${key}`)
  }

  return value || fallback
}

module.exports = getEnv
