export const fonts = [
  {
    heading: 'Inter',
    id: 0,
    body: 'Inter',
    bodyWeights: ['300', '400', '500', '600', '700', '800', '900'],
    headingWeights: ['300', '400', '500', '600', '700', '800', '900'],
  },
  {
    heading: 'Montserrat',
    id: 1,
    body: 'Open Sans',
    bodyWeights: ['300', '400', '600', '700', '800'],
    headingWeights: ['300', '400', '500', '600', '700', '800', '900'],
  },
  {
    heading: 'Playfair Display',
    id: 2,
    body: 'Source Sans Pro',
    bodyWeights: ['200', '300', '400', '600', '700', '900'],
    headingWeights: ['400', '500', '600', '700', '900'],
  },
  {
    heading: 'Work Sans',
    id: 3,
    body: 'Inter',
    bodyWeights: ['300', '400', '500', '600', '700', '800', '900'],
    headingWeights: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  },
  {
    heading: 'Cardo',
    id: 4,
    body: 'Josefin Sans',
    bodyWeights: ['100', '200', '300', '400', '600', '700'],
    headingWeights: ['400', '700'],
  },
  {
    heading: 'Cormorant Garamond',
    id: 5,
    body: 'Proza Libre',
    bodyWeights: ['400', '500', '600', '700'],
    headingWeights: ['300', '400', '500', '600', '700'],
  },
  {
    heading: 'Fira Sans',
    id: 6,
    body: 'Source Sans Pro',
    bodyWeights: ['200', '300', '400', '600', '700', '900'],
    headingWeights: ['300', '400', '500', '600', '700', '800', '900'],
  },
  {
    heading: 'Fjalla One',
    id: 7,
    body: 'Libre Baskerville',
    bodyWeights: ['400', '700'],
    headingWeights: ['400'],
  },
  {
    heading: 'IBM Plex Sans',
    id: 8,
    body: 'IBM Plex Sans',
    bodyWeights: ['100', '200', '300', '400', '500', '600', '700'],
    headingWeights: ['100', '200', '300', '400', '500', '600', '700'],
  },
  {
    heading: 'Libre Baskerville',
    id: 9,
    body: 'Source Sans Pro',
    bodyWeights: ['200', '300', '400', '600', '700', '900'],
    headingWeights: ['400', '700'],
  },
  {
    heading: 'Lora',
    id: 10,
    body: 'Merriweather',
    bodyWeights: ['300', '400', '700', '900'],
    headingWeights: ['400', '500', '600', '700'],
  },
  {
    heading: 'Merriweather Sans',
    id: 12,
    body: 'Merriweather',
    bodyWeights: ['300', '400', '700', '900'],
    headingWeights: ['300', '400', '500', '600', '700', '800'],
  },
  {
    heading: 'Nunito',
    id: 11,
    body: 'Nunito Sans',
    bodyWeights: ['200', '300', '400', '600', '700', '800', '900'],
    headingWeights: ['200', '300', '400', '600', '700', '800', '900'],
  },
  {
    heading: 'Poppins',
    id: 13,
    body: 'Lato',
    bodyWeights: [],
    headingWeights: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  },
  {
    heading: 'Raleway',
    id: 14,
    body: 'Roboto',
    bodyWeights: [],
    headingWeights: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  },
  {
    heading: 'Rubik',
    id: 15,
    body: 'Roboto',
    bodyWeights: [],
    headingWeights: ['300', '400', '500', '600', '700', '800'],
  },
  {
    heading: 'Yeseva One',
    id: 16,
    body: 'Josefin Sans',
    bodyWeights: ['100', '200', '300', '400', '600', '700'],
    headingWeights: [],
  },
]
